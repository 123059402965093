import config from 'config';
import rootStore from '@vue-storefront/core/store';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import type { IProductState } from 'theme/@types/vsf/stores/product';
import type { Content, Data, UserData } from 'theme/stores/fbqConversionApi/fbqConversionApi';

import type { ProductOption } from '../types/ProductOptions';
import type { AppliedCoupon } from '../types/AppliedCoupon';
import type { CartItem } from '../types/FBQEvent';
import type { ProductData } from '../../../../modules/organism/CustomForms/CustomForms';

function getProductCategories(product: IProductState) {
  if (config.zento.analytics.customConfig) {
    const productBreadcrumbs = rootStore.getters['product/breadcrumbs'];

    return productBreadcrumbs?.routes?.length
      ? productBreadcrumbs?.routes?.map((cat: Record<string, any>) => cat.name)
      : product.category
      ? product.category.map((cat) => cat.name)
      : [];
  }

  return product.category ? product.category.map((cat) => cat.name) : [];
}

export function getCurrency() {
  const storeView = currentStoreView();
  return storeView.i18n.currencyCode;
}

export function replaceDiacritics(str: string) {
  if (str !== undefined && str !== null) {
    const normalize = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    return normalize;
  }

  return '';
}

export function getProduct(
  item: IProductState,
  list: string = null,
  cat: string = null,
  position: string | number = null,
) {
  const product = {
    id: item.id || item.entity_id,
    sku: item.sku,
    name: item.name.slice(0, config.zento.theme.category.productBox.productNameLength) + '...',
    category: '',
    variant: [],
    list,
    position,
  };
  const attributeMap = ['id', 'sku', 'name', { priceInclTax: 'price' }, { qty: 'quantity' }];

  attributeMap.forEach((attribute) => {
    const isObject = attribute && typeof attribute === 'object';
    const attributeField = isObject ? Object.keys(attribute)[0] : (attribute as string);
    const attributeName = isObject ? Object.values(attribute)[0] : (attribute as string);

    if (item[attributeField]) {
      const value = item[attributeField];

      if (value) {
        if (attributeField === 'qty' || attributeField === 'priceInclTax') {
          product[attributeName] = value;
        } else {
          product[attributeName] = `${value}`;
        }
      }
    }
  });

  product.position = position ?? null;
  product.list = list ?? null;

  if (item.parentSku) {
    product.sku = item.parentSku;

    if (item.configurable_options && item.configurable_options.length > 0 && !item.options) {
      product.variant = item.configurable_options.map((option: ProductOption) => {
        return { [option.label]: option.values.map((opt) => opt.label).join(', ') };
      });
    } else if (item.options && item.options.length > 0) {
      product.variant = item.options.map((option: ProductOption) => {
        return { [option.label]: option.value };
      });
    } else if (item.totals && item.totals.options) {
      product.variant = item.totals.options.map((option: ProductOption) => {
        return { [option.label]: option.value };
      });
    }
  }

  if (item.category && item.category.length > 0) {
    product.category = item.category.map((cat) => cat.name).join('/');
  } else if (cat) {
    product.category = cat;
  } else {
    product.category = '';
  }

  return product;
}

export function getProducts(items: IProductState[], list: string = null, cat: string = null) {
  const products = [];

  items.forEach((item, index) => {
    products.push(getProduct(item, list, cat, index + 1));
  });

  return products;
}

export function getPromo(promotion: AppliedCoupon) {
  const { code, discount } = promotion;
  const promo = { code, discount };

  return promo;
}

export function getFBQProduct(product: IProductState) {
  return {
    content_ids: product.parentSku ? [product.parentSku] : [product.sku],
    content_name: product.name,
    content_type: 'product',
    content_category: getProductCategories(product),
    currency: getCurrency(),
    value: (product.priceInclTax ?? product.final_price) * (product.qty ?? 1),
  };
}

export function getFBQLead(product: ProductData & { categories: string[] }) {
  return {
    content_name: product['product-name'],
    content_category: product.categories,
    currency: getCurrency(),
    value: product['product-price'],
  };
}

export function getFBQOrder(products: IProductState[]) {
  const content_ids: string[] = [];
  const contents: CartItem[] = [];
  let value = 0;
  let num_items = 0;
  let content_name = '';
  let content_category = [];

  products.forEach((item) => {
    content_ids.push(item.parentSku ? item.parentSku : item.sku);

    contents.push({
      id: item.parentSku ? item.parentSku : item.sku,
      quantity: item.qty,
      item_price: item.price ? item.price : item.priceInclTax,
    });

    num_items += Number(item.qty);

    const price = (item.price ? item.price : item.priceInclTax) * item.qty;
    value += price;

    content_name = content_name + item.name + ', ';
    content_category.push(getProductCategories(item).join(', '));
  });

  content_name = content_name.slice(0, -2); // Remove last comma
  content_category = Array.from(new Set(content_category.join(', ').split(', '))); // Remove duplicates

  return {
    value,
    content_ids,
    contents,
    content_name,
    content_category,
    num_items,
    content_type: 'product',
    currency: getCurrency(),
  };
}

export function getTTQProduct(product: IProductState) {
  return {
    content_id: product.parentSku ? product.parentSku : product.sku,
    content_name: product.name,
    content_type: 'product',
    content_category: product.category ? product.category.map((cat) => cat.name).join('/') : '',
    price: (product.priceInclTax ?? product.final_price) * (product.qty ?? 1),
    quantity: product.qty ?? 1,
  };
}

export function getFBQEventId(action: string, ids?: number[]) {
  let id = '';

  if (ids) {
    id = ids.join('.') + '.';
  }

  return action + '.' + id + Date.now();
}

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }

  return '';
}

export function getFBQConversionData(payload: {
  items: IProductState | IProductState[];
  eventName: string;
  eventId: string;
  sourceUrl: string;
  content_type?: string;
  content_name?: string;
  content_category?: string;
  user_data?: Record<string, any>;
}) {
  const data = Array.isArray(payload.items) ? payload.items : [payload.items];
  let value = 0;

  const content: Content[] = [];
  let customData: Data | null = null;
  let userData: UserData | null = null;

  data.forEach((item) => {
    if (item.final_price) {
      content.push({
        product_id: item.id,
        product_price: item.final_price.toString(),
        quantity: item.qty,
      });

      const price = item.final_price * item.qty;
      value += price;
    }
  });

  customData = {
    content_ids: data.map((item) => item.id),
    content_type: payload.content_type ?? 'product',
    currency: getCurrency(),
    ...(value > 0 ? { value: value.toString() } : null),
    ...(payload.content_name ? { content_name: payload.content_name } : null),
    ...(payload.content_category ? { content_category: payload.content_category } : null),
  };

  if (payload.user_data) {
    userData = {
      city: payload.user_data.city,
      country: payload.user_data.country || payload.user_data.country_id,
      email: payload.user_data.email,
      first_name: payload.user_data.firstName || payload.user_data.firstname,
      last_name: payload.user_data.lastName || payload.user_data.lastname,
      phone: payload.user_data.phoneNumber || payload.user_data.telephone,
      region: payload.user_data.state || payload.user_data.region,
      zip: payload.user_data.zipCode || payload.user_data.postcode,
    };
  }

  return {
    content,
    data: customData,
    eventId: payload.eventId,
    eventName: payload.eventName,
    sourceUrl: payload.sourceUrl,
    userData,
  };
}

export function getEdroneProduct(product: IProductState) {
  return {
    product_skus: product.sku,
    product_titles: product.name,
    product_images: config.images.baseUrl + 'catalog/product' + product.image,
    product_urls: config.zento.theme.storeData.storeUrl + '/' + product.url_path,
    product_category_ids: product.category_ids.join('~'),
    product_category_names: product.category.map((cat) => cat.name).join('~'),
  };
}

export function getEdroneOrderData(lastOrder: Record<string, any>) {
  const skus = [];
  const ids = [];
  const titles = [];
  const productCounts = [];
  const images = [];
  const productUrls = [];
  const categoryNames = [];
  const productCategoryNames = [];
  const productCategoryIds = [];
  let orderProductData = {};

  lastOrder.products.forEach((p: IProductState) => {
    skus.push(p.sku);
    ids.push(p.id);
    titles.push(p.name);
    productCounts.push(p.qty);
    images.push(config.images.baseUrl + 'catalog/product' + p.image);
    productUrls.push(config.zento.theme.storeData.storeUrl + '/' + p.url_path);
    (p.category || []).map((c) => categoryNames.push(c.name));
    productCategoryNames.push(categoryNames.join('~'));
    productCategoryIds.push(p.category_ids.join('~'));
  });

  orderProductData = {
    email: lastOrder.customer.email,
    last_name: replaceDiacritics(lastOrder.customer.lastname),
    first_name: replaceDiacritics(lastOrder.customer.firstname),
    city: lastOrder.customer.city,
    country: lastOrder.customer.country_id,
    phone: lastOrder.customer.telephone,
    sku: skus.join('|'),
    id: ids.join('|'),
    title: titles.join('|'),
    image: images.join('|'),
    product_category_names: productCategoryNames.join('|'),
    product_category_ids: productCategoryIds.join('|'),
    product_urls: productUrls.join('|'),
    product_counts: productCounts.join('|'),
    order_id: lastOrder.orderNumber,
    order_payment_value: lastOrder.total_due,
    base_payment_value: lastOrder.total_due - lastOrder.shipping_amount,
    base_currency: getCurrency(),
    order_currency: getCurrency(),
    coupon: lastOrder.coupon_code,
    action_type: 'order',
  };

  return orderProductData;
}

export function getEdroneSimplifiedOrderData(lastOrder: Record<string, any>, appId: string) {
  const ids = [];
  let orderProductData = {};

  lastOrder.products.forEach((p: IProductState) => {
    ids.push(p.id);
  });

  orderProductData = {
    app_id: appId,
    email: lastOrder.customer.email,
    platform: 'universal',
    action_type: 'universal_order',
    product_ids: ids.join('|'),
    order_id: lastOrder.orderNumber,
    order_payment_value: lastOrder.total_due,
  };

  return orderProductData;
}
